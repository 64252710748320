
html {
    scroll-behavior: smooth;
}

.btn {
    background-color: var(--primary-color);
    color: white;
    padding: 12px 50px;
    width: fit-content;
    min-width: 150px;
    border-radius: 10px;
    font-weight: bold;
    border: none;
    box-shadow: 3px 3px var(--shadow-color);
    transition: all .2s;
    cursor: pointer;
}
.close-btn{
    background: none;
    color: #000;
    border: none;
    font-size: 2rem;
    position: absolute;
    top: -15px;
    right: 0;
}
.close-btn:hover{
    color: #323232;
}
.btn:active{
    box-shadow: none;
    transform: translate(2px);
}
.logo-container {
    display: flex;
    gap: 10px;
}

.links {
    display: flex;
    align-items: center;
    gap: 15px;
}

.logo-container span {
    display: none;
}


.login-tab {
    display: flex;
    gap: 15px;
    align-items: center;
    cursor: pointer;
}
.login-tab .profile-initials:active{
    transform: scale(.95);
}

.login-tab .profile-initials {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    padding: 10px;
    background: rgba(232, 18, 18, 0.58);
    border: 1.5px solid rgba(0, 0, 0, 0.22);
    /* box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22); */
}

/* POPCARD  */
/* .popcard {
    width: 350px;
    height: 350px;
    background-color: #e7e7e7;
    position: absolute;
    top: 60px;
    right: 0;
    border-radius: 25px;
    padding: 25px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: clip-path 0.5s ease-in-out;

} */
.popcard {
    width: 350px;
    max-height: 800px;
    min-height: 500px;
    background: rgb(255, 255, 255);
    backdrop-filter: blur(6px);
    border-radius: 15px;
    text-align: center;
    transition: all 0.5s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    overflow: hidden;
    z-index: 999;
}
.card-background{
    position: fixed;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.25);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
}

.popcard h3 {
    /* font-family: 'Unbounded', sans-serif; */
    font-size: 1.4rem;
    font-weight: 500;
    margin: 20px 0;
}

.popcard form,
.profile-card form {
    border: none;
    margin: 0;
}
.popcard form input{
    margin: 10px 0;
}

.popcard .reset {
    color: #323232;
    margin-left: 5px;
    cursor: pointer;
    display: block;
    text-decoration: underline;
}
.popcard .reset:hover{
    text-decoration: none;
}

.popcard .back {
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
}
.popcard .back svg {
   width: 20px;
   height: 20px;
}

.popcard .btn {
    margin-top: 50px;
}

.popcard .logout {
    background-color: #ff0a5e;
    color: #fff;
    margin: 0;
}

.profile {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    margin: 15px 0;
    padding: 0 20px;
}

.profile li {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    padding: 10px 15px;
    margin: 5px 0;
    transition: background-color 0.1s ease-in-out;
}

.profile li a {
    color: #000;
    gap: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}


/* POPCARD  */
section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
}

section h2 {
    font-family: var(--font-unbounded);
    font-weight: 400;
    font-size: 3rem;
    margin-top: 20px;
    margin-bottom: 25px;
    text-align: center;
}
section h2 b{
    font-family: var(--font-unbounded);
}

#Hero {
    margin: 150px 0;
}
#Hero .gradient-text {
    background-clip: text;
    color: transparent;
    /* background-image: linear-gradient(to right, #37474f, #4A79E0); */
    background-image: linear-gradient(to right, #6F1AE1, #B122DC);
    font-family: 'Unbounded', sans-serif;
}
#Hero img{
    position: absolute;
    top: 50%;
    right: 50px;
}
#Hero h1 {
    font-family: 'Unbounded', sans-serif;
    font-size: 3.5rem;
    margin-bottom: 20px;
    color: #292C3D;
}
#Hero p{
    font-weight: 500;
    color: #292C3D;
    line-height: 26px;
    letter-spacing: .2px;
    width: 40%;
}
.mockup {
    width: 30%;
    position: absolute;
    bottom: -150px;
    right: 0;
    mix-blend-mode: multiply;
}
#Hero .hero-btns{
    display: flex;
    align-items: center;
}
#Hero .invest-btn, #Hero .demo-btn {
    background-color: #292C3D;
    margin-top: 30px;
    letter-spacing: 2px;
    font-weight: 200;
    border-radius: 10px;
    padding: 12px 24px;
    margin-right: 25px;
    transition: all .1s;
    box-shadow: 3px 3px #bbb;
    border: 2px solid #323232;
    font-weight: 600;
    display: flex;
    align-items: center;
}
#Hero .demo-btn {
    color: #292C3D;
    background-color: #fff;
}
#Hero .demo-btn svg{
    width: 20px;
    height: 20px;
    margin-right: 6px;
}
#Hero .demo-btn:active,#Hero .invest-btn:active {
    box-shadow: none;
    transform: translate(2px);
}

#about p {
    width: 60%;
    margin-top: 20px;
    line-height: 1.625;
    font-size: 1.1rem;
}

#why {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #eee;
    padding: 30px 0;
    border-radius: 10px;
}

#why .cards {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: flex-start;
}

#why .cards .card {
    display: flex;
    padding: 25px;
    justify-content: space-between;
    align-items: center;
}
#why .cards .card h3{
    margin-bottom: 10px;
}
#why .cards .card p{
    color: #4b5563;
    line-height: 1.625;
}

.reason-icon {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    color: #000000;
    margin-right: 25px;
  }

#query form {
    border: none;
    width: 100%;
    max-width: 500px;
}

#query form input,
#query form textarea,
.popcard form input,
.profile-card form input,
#Hero input {
    font-family: sans-serif;
    width: 100%;
    padding: 0.875rem;
    font-size: 1rem;
    border: 1.5px solid #000;
    outline: none;
    border-radius: var(--border-radius);
    transition: ease 0.25s;
    margin-bottom: 15px;
}

#query form input:focus,
#query form textarea:focus,
.popcard form input:focus,
.profile-card form input:focus,
#Hero input:focus {
    box-shadow: 2px 2px 0 black;
    scale: 1.01;
}
#Hero input {
    width: 340px;
}

#query form textarea {
    height: 150px;
    resize: none;
    border-radius: 10px;
}

#query .note {
    font-family: "Delicious Handrawn", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    letter-spacing: .5px;
    color: #323232af;
    margin: 10px 0 50px 0;
}

footer {
    background-color: #111827;
    color: #9CA3AF;
    padding: 4rem 0;
    min-height: fit-content;
}

footer h3,
footer h4 {
    color: #fff;
}

hr {
    width: 75%;
    margin: 0 auto;
    border-bottom: 0.5px solid #9ca3af16;
}

.company-details,
.company-contact,
.company-legal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.company-details {
    width: 40%;
}

.company-details h3 {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Unbounded', sans-serif;
}

.company-legal {
    padding-bottom: 5rem;
}

footer a:hover {
    color: #fff;
}

footer a {
    color: #9CA3AF;
}

.footer-details {
    display: flex;
    justify-content: space-evenly;
    position: relative;
}

.footer-contact li a:hover {
    color: #fff;
}

.footer-bottom {
    margin: 1.2rem 0;
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 1.2rem;
}

.disc-tnc a:first-child {
    margin: 0 1.5rem;
}

.developer-details {
    text-align: center;
    font-size: 0.85em;
}

.disclamier-container,
.tnc-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
}

.disclamier,
.tnc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    width: 80%;
    min-height: 55vh;
}

.tnc h2 {
    margin-top: 10px;
}

.tnc ol {
    list-style-type: lower-alpha;
    margin-left: 25px;
}

@media only screen and (max-width: 600px) {
    .popcard{
        height: 80%;
        width: 95%;
    }
    section h2 {
        font-size: 2.5rem;
    }

    #Hero {
        align-items: flex-start;
        margin: 130px 0;
    }
    #Hero .hero-btns{
        display: block;
        margin-top: 30px;
    }
    #Hero h1 {
        font-size: 2.5rem;
    }
    #Hero p {
        width: 100%;
    }
    #about p{
        width: 80%;
    }
    .mockup {
        display: none;
    }
    #why .cards {
        width: 100%;
    }
    .footer-details {
        flex-direction: column;
        padding: 0 2rem;
        gap: 3rem;
    }

    .company-details {
        width: 100%;
    }

    .footer-bottom {
        flex-direction: column;
    }

    hr {
        width: 80%;
    }

    .copyright {
        text-align: center;
    }

    .disc-tnc {
        justify-content: center;
    }

}