.holding-details h1{
    margin-bottom: 10px;
    text-align: center;
}
.holding-details h2{
    margin: 20px 0 10px 0;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup {
    overflow-y: auto;
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
    width: 90%;
    position: absolute;
  }
  
  .popup h4 {
    margin-bottom: 5px;
  }
  .popup .amount {
    margin-bottom: 28px;
  }
  .popup button {
    margin-top: 10px;
    padding: 5px 10px;
  }
  .duration-btns{
    display: flex;
    margin: 10px 0;
    justify-content: center;
    gap: 15px;
  }
  .duration-btns button{
    background-color: #fff;
    color: #6a6a6a;
    border: none;
    margin-right: 5px;
    font-weight: bold;
  }
  .duration-btns button.active {
    background-color: #3CD7AB;
    color: white;
  }
  
  .graph-loading{
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .detail-title{
    color: #6a6a6a;
  }

