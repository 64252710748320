@import url('https://fonts.googleapis.com/css2?family=Mona+Sans:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap&family=Delicious+Handrawn');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Mona Sans", serif;
}

:root {
  /* Colors */
  --primary-color: #343434;
  --shadow-color: #bbb;

  /* Typography */
  --font-unbounded: 'Unbounded', sans-serif;
  --font-mona: 'Mona Sans', sans-serif;
  
  /* Spacing */
  --spacing-unit: 5px;
  --border-radius: 10px;
}

.container {
  /* padding: 20px; */
  padding: calc(5 * var(--spacing-unit));
  background-color: #ffffff;
}
.logo-text{
  font-size: 1.5em;
  font-weight: bold;
  font-family: 'Unbounded', sans-serif;
}
.logo-text.hidelogo{
 display: none;
}
.logo-text.showlogo{
 display: block;
}
.logo-text a{
  color: #000;
}

.logo-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}
.dropdown-item:hover {
  background-color: #f0f0f0;
}
.choose-fund{
  padding: 15px 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #acacac;
  color: #181820;
}

.input-field input, .date-filters input{
  width: 100%;
  border: 1px solid #ddd;
  padding: 15px 10px;
  margin: 10px 0;
  height: 30px;
  border-radius: 5px;
}
.input-field .icon{
  position: relative;
}
.input-field .icon svg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  stroke: #878787;
}
.download-statement svg {
  float: right;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.input-field .icon svg:active{
  stroke: #000;
}
.edit-field input {
  border: 2px solid #ddd;
  padding: 15px 10px;
  margin: 10px 0;
  height: 30px;
  border-radius: 5px;
}
.edit-input{
  background: none;
  border: 1px dashed #ccc;
  padding: 5px;
  width: 80px;
  font-weight: bold;
  font-size: 16px;
}
.edit-input:active, .edit-input:focus{
border: 2px solid #000;
}
.input-field input:focus, .edit-field input:focus, .date-filters input:focus {
  border: 1px solid #000;
  outline: none;
}

button {
  margin-top: 15px;
  width: fit-content;
  padding: 8px 20px;
  border-radius: 5px;
  outline: none;
  background: none;
  cursor: pointer;
  color: white;
  text-decoration: none;
  background-color: #000;
}

.back-btn svg{
  cursor: pointer;
  height: 25px;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.history-table th,
.history-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.history-table td {
  cursor: pointer;
}
.history-table tbody tr:hover {
  background-color: #f2f2f2;
  font-weight: bold;
}

.history-table th {
  background-color: #f2f2f2;
}

.table-heading{
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  list-style: none;
  color: #343434;
}
.table-heading li:nth-child(1),.table-heading li:nth-child(2), .table-heading li:nth-child(3){
  display: none;
}
.user-row, .users-table ul, .user-data{
  display: flex;
  list-style: none;
  align-items: center;
  transition: all .2s;
}

.initials{
  margin-right: 20px;
  background-color: #eaeaea;
  padding: 8px;
  border-radius: var(--border-radius);
  transition: all .2s;
}
.user-row{
  padding: 15px;
  margin: 10px 0;
  border-radius: var(--border-radius);
  background-color: #F6F6F6;
  cursor: pointer;
}
.user-data{
  justify-content: space-between;
  width: 100%;
}
.user-data h4, .user-data p{
  color: var(--primary-color);
}
.user-row:hover{
  background-color: #eaeaea;
}
.user-row:hover .initials{
  background-color: #F6F6F6;
  font-weight: 600;
}
.user-row:hover .user-data h4, .user-row:hover .user-data p{
  color: #000;
}
.user-data p{
  display: none;
}

.icon{
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-initials{
  border: 2px solid #ddd;
  padding: 5px;
  border-radius: 5px;
}
.product {
  background-color: #EDF1F6;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 30px 0;
  position: relative;
}

.product .folio {
  margin-bottom: 8px;
}
.product .porfolio-heading {
  margin-bottom: 8px;
}

.detail {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

#loader {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}

.user-details {
  margin-top: 20px;
}
.Sip-youtube iframe{
  width: 100%;
 }
.card{
  margin: 25px 0;
}

/* for pc */


.total-pnl {
  font-size: .8em;
}

.total-pnl-value {
  font-size: 1.5em;
  margin: 8px 0;
}

.investment-details-card {
  background-color: #1e1e1e;
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.investment-details-card h2 {
  margin: 0;
  font-size: 1.5em;
}

.investment-details-card p {
  margin: 5px 0;
  font-size: 0.9em;
}

.total-value {
  font-size: 2em;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.percentage {
  margin-left: 10px;
  color: #6ac045;
}

.month {
  font-size: 0.7em;
  color: #aaaaaa;
}

.investments {
  margin-top: 20px;
}

.investment {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.box {
  display: flex;
  gap: 5px;
}

.investment-bar {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.investment-1 {
  background-color: #51e59e;
}

.investment-2 {
  background-color: #00cfff;
}

.investment-3 {
  background-color: #b06cd8;
}

.investment-4 {
  background-color: #878787;
}
.investment-5 {
  background-color: #d8996c;
}
.investment-6 {
  background-color: #d8cb6c;
}

.investment-details {
  display: flex;
  flex-direction: column;
  margin-left: 10px;

}

.investment-type {
  font-weight: bold;
  font-size: 1.1em;
}

.investment-amount {
  font-size: 1em;
}

.investment-percentage {
  font-size: 0.9em;
  color: #aaaaaa;
  display: flex;
}

.investment-percentage span {
  height: 8px;
  width: 8px;
  margin-left: 5px;
}

.investment-percentage .up{
  color: #6ac045;
  width: 18px;
}

.investment-percentage .down {
  color: #c04545;
  width: 18px;
}

.date-input {
  display: flex;
  align-items: center;
}

form{
  border: 1px solid #000;
  padding: 15px;
  border-radius: 5px;
  margin: 20px 0;
}

.edit-icon {
  float: right;
}
.edit-icon button{
  margin: 0;
}
.name h3{
  cursor: pointer;
  transition: color .1s;
}
.name h3:hover{
  color: #575757;
}
.history-date {
  margin-bottom: 20px;
}


.history-entry {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}

.history-entry p {
  margin: 5px 0;
  color: #555;
}

.history-entry p strong {
  color: #333;
}

.hidden {
  display: none;
}


/* SIP Calculator */
.form-group {
  margin-bottom: 35px;
}
.form-group input[type=number]::-webkit-inner-spin-button, 
.form-group input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
  }
  .inv-amount{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
}

.inv-amount input{
  outline: none;
  border: none;
  border-bottom: 2px solid #6f6f6f;
  padding: 8px 15px;
  font-size: 20px;
  position: relative;
  width: 150px;
}
.inv-amount input:focus{
  border-bottom: 2px solid #000000;
}


.inv-input {
  display: flex;
  align-items: center;
  height: 40px;
}

.inv-rate, .inv-period {
  display: flex;
  justify-content: space-around;
}

.inv-rate input, .inv-period input {
  width: 150px;
  text-align: center;
  padding: 5px 10px;
  height: 100%;
  border: 2px solid #333;
}
.dec {
  border-radius: 5px 0 0 5px;
}
.inc{
  border-radius: 0 5px 5px 0;
}
.inv-rate button, .inv-period button {
  height: 100%;
  font-size: 18px;
  margin: 0;
}

.result{
  margin-top: 25px;
  text-align: center;
}

.why-to-invest{
  margin-top: 30px;
}
.why-to-invest ol li{
  margin: 20px;
  list-style: none;
}
.profile-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  background-color: #f0f0f0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  max-width: 350px;
  position: relative;
  overflow: hidden;
}
.loading-state{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

}
.loading-state svg{
  height: 30px;
  width: 30px;
}
.loading-state svg path{
  fill: #000;
}


/* ADMIN PAGE */

/* for pc */

@media screen and (min-width: 720px), print {
  form div span{
    width: 25px;
  }
  .admin-page{
    display: flex;
    justify-content: space-around;
    gap: 50px;
    align-items: center;
    
  }
  .admin-page form{
    height: fit-content;
  }
  .admin-page .clients-container{
    width: 80%;
    margin-bottom: 50px;
  }
  .admin-page .search-bar div{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .admin-page .clients-container .clients{
    overflow-y: auto;
    height: 70vh;
  }
  .user-details {
    display: flex;
    justify-content: space-between;
  }
  .table-heading li:nth-child(1),  .table-heading li:nth-child(2), .table-heading li:nth-child(3){
    display: block;
  }
  .user-data p{
    display: block;
  }
  .product .details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .product .detail {
    flex-direction: column;
  }

  .product .detail p:first-child {
    font-size: .9em;
  }
  
  .product .detail p:last-child {
    font-size: 1.2em;
  }
}