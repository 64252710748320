nav {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: #fff;
    /* -webkit-backdrop-filter: blur(10px); */
    backdrop-filter: blur(10px);
    padding: 1.5rem 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
nav .mobile-menu{
    display: none;
}
nav .mobile-menu svg{
    width: 25px;
    cursor: pointer;
    stroke-width: 2px;
}
nav .nav-links{
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.nav-closed {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: clip-path 0.5s ease-in-out;
}

nav .logo{
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    font-family: 'Unbounded', sans-serif;
}
nav .links{
    display: flex;
    gap: 30px;
    margin-right: 30px;
}
nav .link{
    color: #000;
}
nav .link:hover{
    color: #686868;
}

.user-profile-popup{
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    position: absolute;
    top: 100px;
    right: 30px;
    background-color: #fff;
    padding: 30px 45px;
    box-shadow: 0px 15px 50px 0px rgba(27, 32, 50, 0.1);
    border-radius: var(--border-radius);
    transform: translateY(-10px);
    transition: all 0.3s ease;
}
/* For the "hide" state */
.user-profile-popup.hide {
    display: block; /* Keep block for transitions */
    opacity: 0;
    pointer-events: none; /* Disable interactions */
    transform: translateY(-10px);
}

/* For the "show" state */
.user-profile-popup.show {
    display: block;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
}
.user-profile-popup .user-name{
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
}
.user-profile-popup .user-email{
    text-align: center;
    margin-bottom: 25px;
    font-size: .8rem;
}
.user-profile-popup ul{
    list-style: none;
}
.user-profile-popup ul li{
    margin: 10px 0;
}
.user-profile-popup ul li a, .user-profile-popup ul li span{
    color: #000;
    cursor: pointer;
}
.user-profile-popup ul li a:hover, .user-profile-popup ul li span:hover{
    color: #565564;
}
.user-profile-popup ul li:last-child{
    border-top: 1px solid #d8d6d5;
    margin-top: 24px;
    padding-top: 20px;
}

@media only screen and (max-width: 600px) {
    nav .mobile-menu{
        display: block;
    }
    .user-profile-popup, .links{
        width: 100%;
        top: 90px;
        right: 0;
        border-radius: 0;
        box-shadow: none;
        border-top: 0.5px solid #d8d6d5;
    }
    .links{
        position: absolute;
        left: 0;
        flex-direction: column;
        background-color: #fff;
        padding: 25px 0;
    }
    .links.hide {
        opacity: 0;
        pointer-events: none; /* Disable interactions */
    }
    
    /* For the "show" state */
    .links.show {
        opacity: 1;
        pointer-events: auto;
    }

    /* Keyframes for the animation */
@keyframes translateInX {
    from {
      transform: translateX(-20px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Common link styles */
  .link {
    opacity: 0; /* Ensure links are initially invisible */
    transform: translateX(-20px); /* Set the starting position */
    animation-timing-function: ease-out;
    animation-fill-mode: forwards; /* Ensure links stay in final position after animation */
  }
  
  /* Dynamic animation delays */
  .link0 {
    animation-duration: 0.3s;
    animation-delay: 0.1s;
  }
  
  .link1 {
    animation-duration: 0.3s;
    animation-delay: 0.2s;
  }
  
  .link2 {
    animation-duration: 0.3s;
    animation-delay: 0.3s;
  }
  
  .link3 {
    animation-duration: 0.3s;
    animation-delay: 0.4s;
  }
  
  /* Show animation only when the links container has the 'show' class */
  .links.show .link {
    animation-name: translateInX;
  }
  
}