
.options{
    /* opacity: 0; */
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    position: absolute;
    right: 30px;
    top: 50px;
    background-color: #fff;
    /* padding: 20px 30px; */
    padding: 10px;
    min-width: 160px;
    box-shadow: 0px 15px 50px 0px rgba(27, 32, 50, 0.1);
    border-radius: var(--border-radius);
    transform: translateY(-10px);
    transition: all 0.3s ease;
}
.options ul{
    list-style: none;
}
.options ul li{
     cursor: pointer;
     padding: 5px;
}

.options ul li:hover{
     color: #343434;
     /* background-color: #f5f5f5; */
}
.options ul li:last-child{
    color: #DC2626;
    border-top: 1px solid #d8d6d5;
    margin-top: 10px;
    padding-top: 10px;
}

/* For the "hide" state */
.options.hide {
    display: block; /* Keep block for transitions */
    opacity: 0;
    pointer-events: none; /* Disable interactions */
    transform: translateY(-10px);
}

/* For the "show" state */
.options.show {
    display: block;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
}

.edit-icon svg{
    width: 25px;
    cursor: pointer;
}