.sip-calculator {
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 30px;
    width: 350px;
    margin: 50px auto;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    border: 2px solid #323232;
    box-shadow: 4px 4px #323232;
  }
  
  .sip-calculator h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 50px;
  }
  
  .slider-group {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }
  .slider-label{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .slider-group label {
    font-size: 14px;
    font-weight: 500;
  }

  .slider-group input[type="range"] {
    width: 100%;
    margin: 10px 0;
    background-color: #D5DBE1;
  }
  
  .slider-group span {
    font-size: 16px;
    font-weight: bold;
  }
  
  .output {
    margin: 30px 0 15px 0;
  }
  
  .output p span {
    font-size: 2rem;
    font-weight: bold;
    margin: 10px 0;
    font-family: 'unbound', sans-serif;
  }
  
  .output .profit {
    color: green;
    font-size: 18px;
    margin-top: 5px;
  }
  
.range-slider {
    appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background: #D5DBE1;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    background: linear-gradient(to right, #000000 0%, #000000 0%) no-repeat;
  }
  
  .range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #000000;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  
  .range-slider::-webkit-slider-thumb:hover {
    box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.16);
    transition: 0.3s ease-in-out;
  }
  
  .range-slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #000000;
    cursor: pointer;
  }
  
  .range-slider-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid #C6CACE;
    box-shadow: 0px 1px 2px 0px #1F1E241F;
  }
  
@media screen and (min-width: 720px) {
  .sip-calculator{
    width: 400px;
  }
}
